
// import React, { useState, useEffect } from "react";
// import Styles from "../../components/Questions/Questions.module.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// import legalService from "../../services/legals/LegalService"; // Importa el servicio que acabas de actualizar

// function Questions() {
//   const [openFAQ, setOpenFAQ] = useState(null);
//   const [faqs, setFaqs] = useState([]);

//   useEffect(() => {
//     // Lógica para hacer la solicitud a la API y actualizar el estado con los datos recibidos
//     const getFaqsData = async () => {
//       try {
//         const faqsData = await legalService.getFaqs();
//         setFaqs(faqsData);
//       } catch (error) {
//         // Manejar errores
//         console.error('Error fetching FAQs:', error);
//       }
//     };

//     getFaqsData();
//   }, []);

//   function handleToggle(index) {
//     if (openFAQ === index) {
//       setOpenFAQ(null);
//     } else {
//       setOpenFAQ(index);
//     }
//   }

//   return (
//     <div>
//       <div
//         className="mt-[32px] md:mt-[100px] mb-[32px] md:mb-[100px]"
//         id="FAQs"
//       >
//         <span
//           className={`text-[24px] md:text-[48px] flex justify-center ${Styles.title}`}
//         >
//           Frequently asked questions
//         </span>
//       </div>
//       <div className="mx-[16px] md:mx-[72px]">
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
//           {faqs.map((faq, index) => (
//             <div
//               key={index}
//               onClick={() => handleToggle(index)}
//               className={`w-auto ${index % 2 !== 0 ? "2xl:ml-80" : ""}`}
//             >
//               <h2
//                 className={`flex md:items-center items-start text-left faqQuestion text-[16px] md:text-[20px] ${Styles.faqQuestion}`}
//               >
//                 <span
//                   className={`flex items-center w-4 h-4 mr-4 mt-1 md:mt-0 leading-[24px] md:leading-[36px] transform ${
//                     openFAQ === index ? "rotate-180" : "rotate-0"
//                   } transition-transform`}
//                 >
//                   <FontAwesomeIcon
//                     icon={faChevronDown}
//                     className={Styles.arrow}
//                   />
//                 </span>
//                 {faq.question}
//               </h2>

//               {openFAQ === index && (
//                 <p
//                   className={`text-[14px] md:text-[16px] pl-[40px] pr-[8px] mt-4 leading-[20px]
//                   md:leading-[24px]
//               ${Styles.faqAnswer}`}
//                   ml-8
//                 >
//                   {faq.answer}
//                 </p>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Questions;
//Comentario para subir a dev

import React, { useState, useEffect } from "react";
import Styles from "./Questions.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import legalService from "../../services/legals/LegalService"; // Importa el servicio que acabas de actualizar

function Questions() {
  const [openFAQs, setOpenFAQs] = useState([]);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    // Lógica para hacer la solicitud a la API y actualizar el estado con los datos recibidos
    const getFaqsData = async () => {
      try {
        const faqsData = await legalService.getFaqs();
        setFaqs(faqsData);
        setOpenFAQs(Array(faqsData.length).fill(false));
      } catch (error) {
        // Manejar errores
        console.error('Error fetching FAQs:', error);
      }
    };

    getFaqsData();
  }, []);

  const halfLength = Math.ceil(faqs.length / 2);
  const firstHalf = faqs.slice(0, halfLength);
  const secondHalf = faqs.slice(halfLength);

  const handleToggle = (index) => {
    const newOpenFAQs = [...openFAQs];
    newOpenFAQs[index] = !newOpenFAQs[index];
    setOpenFAQs(newOpenFAQs);
  };

  return (
    <div className="container mx-[16px] md:mx-[72px] w-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 pb-[10em]">
        <div className="w-auto">
          {firstHalf.map((faq, index) => (
            <div key={index} className={`my-4 ${index === firstHalf.length - 1 ? 'mb-0 md:mb-4' : ''}`}>
              <h2
                onClick={() => handleToggle(index)}
                className={`flex md:items-center items-start text-left faqQuestion text-[16px] md:text-[20px] ${Styles.faqQuestion}`}
              >
                <span
                  className={`flex items-center w-4 h-4 mr-4 mt-1 md:mt-0 leading-[24px] md:leading-[36px] transform ${
                    openFAQs[index] ? "rotate-180" : "rotate-0"
                  } transition-transform`}
                >
                  <FontAwesomeIcon icon={faChevronDown} className={Styles.arrow} />
                </span>
                {faq.question}
              </h2>

              {openFAQs[index] && (
                <p
                  className={`text-[14px] md:text-[16px] pl-[35px] pr-[8px] mt-4 leading-[20px] md:leading-[24px] normal font-normal ${Styles.faqAnswer2}`}
                >
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="w-auto">
          {secondHalf.map((faq, index) => (
            <div key={index + halfLength} className={`my-4 ${index === 0 ? 'mb-0 md:mb-4' : ''}`}>
              <h2
                onClick={() => handleToggle(index + halfLength)}
                className={`flex md:items-center items-start text-left faqQuestion text-[16px] md:text-[20px] ${Styles.faqQuestion}`}
              >
                <span
                  className={`flex items-center w-4 h-4 mr-4 mt-1 md:mt-0 leading-[24px] md:leading-[36px] transform ${
                    openFAQs[index + halfLength] ? "rotate-180" : "rotate-0"
                  } transition-transform`}
                >
                  <FontAwesomeIcon icon={faChevronDown} className={Styles.arrow} />
                </span>
                {faq.question}
              </h2>

              {openFAQs[index + halfLength] && (
                <p
                  className={`text-[14px] md:text-[16px] pl-[35px] pr-[8px] mt-4 leading-[20px] md:leading-[24px]  normal font-normal ${Styles.faqAnswer2}`}
                >
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Questions;
